import React, { useCallback, useEffect, useRef } from 'react';
import { ProductId } from 'helpers/businessConstants';
import { getFullQuoteAndBuyRoute, getSiteUrl } from 'helpers/routingHelper';
import { generateSysTimestamp, getRenewalDate } from './helpers';

const PAYSAFE_FORM_ACTION = process.env.GATSBY_PAYSAFE_FORM_ACTION;
const PAYSAFE_NBX_INTERFACE = process.env.GATSBY_NBX_INTERFACE;
const SITE_URL = getSiteUrl();
const nbxSuccessUrl = process.env.GATSBY_NBX_SUCCESS_URL;

export type PaymentPayload = {
  nbxChecksum: string;
  rsaChecksum: string;
  nbxMerchantReference: string;
  paymentAmount: string;
  quoteNumber: string;
  customerPaymentDetails: {
    firstName: string;
    lastName: string;
    email: string;
    houseNumber: string;
    postcode: string;
  };
  productId: ProductId;
  selectedBundleCovers: string[];
  movementDate: string;
};

const PaymentRedirect: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const updateInputValue = (id: string, value: string): void => {
    const formInputs = formRef.current?.elements as HTMLCollectionOf<HTMLInputElement>;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formInputs.namedItem(id)!.value = value;
  };

  const submitForm = useCallback((payload: PaymentPayload): void => {
    updateInputValue('nbx_checksum', payload.nbxChecksum);
    updateInputValue('RSA_checksum', payload.rsaChecksum);
    updateInputValue('nbx_merchant_reference', payload.nbxMerchantReference);
    updateInputValue(
      'RSA_policyholder_forename',
      payload.customerPaymentDetails.firstName
    );
    updateInputValue('RSA_policyholder_surname', payload.customerPaymentDetails.lastName);
    updateInputValue(
      'nbx_cardholder_name',
      `${payload.customerPaymentDetails.firstName} ${payload.customerPaymentDetails.lastName}`
    );
    updateInputValue('nbx_email', payload.customerPaymentDetails.email);
    updateInputValue('nbx_houseno', payload.customerPaymentDetails.houseNumber);
    updateInputValue('nbx_postcode', payload.customerPaymentDetails.postcode);
    updateInputValue('nbx_payment_amount', payload.paymentAmount);
    updateInputValue('RSA_quote_reference', payload.quoteNumber);
    updateInputValue('RSA_sys_timestamp', generateSysTimestamp(payload.movementDate));
    updateInputValue('RSA_product_ID', payload.productId);
    updateInputValue('RSA_policy_renewaldate', getRenewalDate());
    updateInputValue('RSA_coversection', payload.selectedBundleCovers.join('|'));

    formRef.current?.submit();
  }, []);

  /* When the page is loaded, we use the postMessage API to let the payment page know this (redirect) page is ready
   * so it can send the relevant data to the iframe. We then take that data and use a form submission to send data to
   * the hosted UPP page.
   */
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (
        SITE_URL &&
        event.origin === new URL(SITE_URL).origin &&
        event.data.type === 'submit'
      ) {
        submitForm(event.data.payload);
      }
    });

    window.parent.postMessage({ type: 'ready' }, SITE_URL || '');
  }, [submitForm]);

  return (
    <form ref={formRef} name="redirectForm" action={PAYSAFE_FORM_ACTION} method="POST">
      {/* Transaction fields */}
      <input type="hidden" name="nbx_currency_code" value="GBP" />
      <input type="hidden" name="use_three_d_secure_version_2" value="1" />
      <input
        type="hidden"
        name="3ds2"
        value="{'merchantUrl':'https://pet-insurance.johnlewisfinance.com/'}"
      />

      <input type="hidden" name="nbx_merchant_reference" />
      <input type="hidden" name="nbx_payment_amount" />
      <input type="hidden" name="nbx_checksum" />
      <input type="hidden" name="RSA_checksum" />
      <input type="hidden" name="RSA_sys_timestamp" />

      {/* Customer fields */}
      <input type="hidden" name="nbx_cardholder_name" />
      <input type="hidden" name="nbx_houseno" />
      <input type="hidden" name="nbx_postcode" />
      <input type="hidden" name="nbx_email" />
      <input type="hidden" name="RSA_policyholder_forename" />
      <input type="hidden" name="RSA_policyholder_surname" />

      {/* Quote fields */}
      <input type="hidden" name="RSA_quote_reference" />

      {/* General Nextbanx fields */}
      <input type="hidden" name="nbx_ca_accepted" value="" />
      <input type="hidden" name="nbx_start_date" value="" />
      <input type="hidden" name="nbx_expiry_date" value="" />
      <input type="hidden" name="nbx_error_code" value="n/a" />
      <input type="hidden" name="nbx_error_message" value="" />
      <input type="hidden" name="nbx_status" value="" />
      <input type="hidden" name="nbx_success_show_content" value="" />
      <input type="hidden" name="nbx_accessible" value="0" />
      <input type="hidden" name="nbx_ca_permission" value="1" />
      <input type="hidden" name="nbx_interface" value={PAYSAFE_NBX_INTERFACE} />
      <input type="hidden" name="nbx_interface_id" value={PAYSAFE_NBX_INTERFACE} />

      {/* URLs */}
      <input type="hidden" name="nbx_success_url" value={nbxSuccessUrl} />
      <input
        type="hidden"
        name="nbx_redirect_url"
        value={getFullQuoteAndBuyRoute('paymentDone')}
      />
      <input
        type="hidden"
        name="nbx_success_redirect_url"
        value={getFullQuoteAndBuyRoute('paymentDone')}
      />
      <input
        type="hidden"
        name="nbx_return_url"
        value={getFullQuoteAndBuyRoute('paymentBack')}
      />
      <input
        type="hidden"
        name="nbx_failure_redirect_url"
        value={getFullQuoteAndBuyRoute('paymentError')}
      />
      <input type="hidden" name="RSA_env_URL" value={SITE_URL} />

      {/* CGI call fields */}
      <input type="hidden" name="RSA_product_ID" />
      <input type="hidden" name="RSA_policy_renewaldate" />
      <input type="hidden" name="RSA_customer_ID" value="PET_CUSTOMER" />
      <input type="hidden" name="RSA_new_customer_flag" value="1" />
      <input type="hidden" name="RSA_policy_movement_type" value="NWA_ADD" />
      <input type="hidden" name="RSA_brand" value="JohnLewis" />
      <input type="hidden" name="RSA_payment_transaction_type" value="NB" />
      <input type="hidden" name="RSA_coversection" value="" />
      <input type="hidden" name="RSA_product" value="PET" />

      {/* These parameters are required because the CGI call needs them, but do not really apply here... */}
      <input type="hidden" name="RSA_vehicle_enginesize" value="PET" />
      <input type="hidden" name="RSA_vehicle_yom" value="1920" />
      <input type="hidden" name="RSA_vehicle_make" value="PET" />
      <input type="hidden" name="RSA_vehicle_model" value="PET" />
      <input type="hidden" name="RSA_mtastartdate" value="" />
      <input type="hidden" name="RSA_direct_debit_account_name" value="" />
      <input type="hidden" name="RSA_direct_debit_account_number" value="" />
      <input type="hidden" name="RSA_direct_debit_sort_code" value="" />
      <input type="hidden" name="RSA_direct_debit_due_date" value="" />
    </form>
  );
};

export default PaymentRedirect;
